@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
.login {
  /*margin: 150px auto 0;
  max-width: 450px;*/
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -100px;
}
.login #logo {
  width: 180px;
  margin-bottom: 15px;
}
.content {
  /*width: 100%;*/
  margin-top: 20px;
  border-radius: 10px;
  padding: 30px;
  border: 2px solid rgb(245, 245, 245);
  box-shadow: 0px 15px 10px -6px rgb(177, 177, 177);
  /*text-align: center;*/
  margin: 15px;
}
.content > p {
  color: rgb(153, 153, 153);
}
.content > h1 {
  color: rgb(109, 109, 109);
  text-transform: uppercase;
  font-size: 30px
}
.login .content {
  max-width: 450px;
  width: 100%;
}
/* Mobile Layout: 320px. */
@media only screen and (max-width: 552px) {
  .login #logo {
    width: 150px;
    margin-bottom: 15px;
  }
  .content {
    padding: 8px;
}
}
/* Variáveis*/
:root {
  --colorDefault: #009ce2;
  --colorDefaultHover: #0080bd;
  --secondColor: #2ac705;
  --thirdColor: #fde122;
  --colorText: #4b4b4b;
  --colorText2: #919191;
  --backgroundDefault: #f8f8f8;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

/* Scrollbar*/
::-webkit-scrollbar { width: 6px;  }/* for vertical scrollbars */
::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.0); }
::-webkit-scrollbar-thumb { background: #009ce2; background: var(--colorDefault); }

@font-face {
  font-family: 'Kalam-Regular';
  font-size: 18px;
  src: local('Kalam-Regular'), url(/static/media/Kalam-Regular.ca4a04f6.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Kalam-Bold';
  src: local('Kalam-Bold'), url(/static/media/Kalam-Bold.d81b8c41.ttf) format('truetype');
}

@font-face {
  font-family: 'Chewy-Regular';
  src: local('Chewy-Regular'), url(/static/media/Chewy-Regular.e94e4ae6.ttf) format('truetype');
}
.color-primary {
  color: #009ce2;
  color: var(--colorDefault);
}
.bg-primary {
  background-color: #009ce2;
  background-color: var(--colorDefault);
}
.color-second {
  color: #2ac705;
  color: var(--secondColor);
}
.bg-second {
  background-color: #2ac705;
  background-color: var(--secondColor);
}
.color-third {
  color: #fde122;
  color: var(--thirdColor);
}
.bg-third {
  background-color: #fde122;
  background-color: var(--thirdColor);
}

html, body, #root {
  min-height: 100%;;
  font-family: 'Kalam-Regular';
}

body {
  background: #f8f8f8;
  background-size: cover;
  -webkit-font-smoothing: antialiased !important;
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: none !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Kalam-Bold';
}
ul {
  list-style: none;
}
p {
  font-size: 18px;
}
a {
  color: #009ce2;
  color: var(--colorDefault);
}
a:hover {
  text-decoration: none;
  color: #0080bd;
  color: var(--colorDefaultHover);
}
.btn {
  font-size: 18px;
}
.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: none !important;
  --webkit-box-shadow: none !important;
}
.btn-input {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  margin-top: -68px;
  margin-right: 25px;
  background: white;
  /* padding: 5px; */
  border-radius: 50%;
  width: 45px;
  height: 45px;
  box-shadow: 0px 0px 5px -2px #d3d3d3;
  --webkit-box-shadow: 0px 0px 5px -2px #d3d3d3;
}
.btn-input i {
  font-size: 1.2em !important;
}
/*body, input, button {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 14px;
}*/
.form-control {
  /*padding-top: 25px;*/
  font-family: 'Kalam-Regular';
  height: 60px;
  padding: 2em 0.8rem 0.8em 0.8rem;
}
.form-control:focus {
  color: #4b4b4b;
  color: var(--colorText);
  background-color: #fff;
  border-color: #c7c7c7;
  outline: 0;
  box-shadow: none;
  --webkit-box-shadow: none;
}
button:focus {
  outline: none;
}
form {
  display: flex;
  flex-direction: column;
}
form label {
  font-size: 14px;
  color: #444;
  font-weight: bold;
  margin-bottom: 8px;
}
.labelInput {
  position: absolute;
  margin-left: 10px;
  margin-top: 2px;
  /*font-size: 12px;*/
}
.labelImage {
  font-size: 14px;
  background: white;
  /*float: left;*/
}
form label span {
  font-weight: normal;
  color: #999;
  font-size: 12px;
}
form input {
  border: 1px solid #ddd;
}
.btn {
  border: 0;
  border-radius: 5px;
  font-weight: bold;
  background: #009ce2;
  background: var(--colorDefault);
  color: #fff;
  cursor: pointer;
  padding: 12px 15px;
}
.btn-default {
  background: #009ce2;
  background: var(--colorDefault);
  color: #fff;
}
.btn-default:hover {
  background: #0080bd;
  background: var(--colorDefaultHover);
  color: #fff;
}
.btn-default-outline {
  padding: 15px 22px;
  border-radius: 30px;
  background: #f8f8f8;
  background: var(--backgroundDefault);
  border: 2px solid #009ce2;
  border: 2px solid var(--colorDefault);
  color: #009ce2;
  color: var(--colorDefault);
}
.btn-default-outline:hover {
  background: #009ce2;
  background: var(--colorDefault);
  color: #fff;
}
.btn-delete {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}
.btn-delete:hover {
  background: #1d1d1d;
  border-color: #1d1d1d;
  color: #fff;
}
.btnLink {
  color: #009ce2;
  color: var(--colorDefault);
  cursor: pointer;
  border: 0;
  background: transparent;
}
.btnCloseModal {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px;
  z-index: 99;
  color: #2b2b2b;
  color: #009ce2;
  color: var(--colorDefault);
  padding: 5px;
  font-size: 25px;
  border: 0;
  background: transparent;
}
.btn-outline {
  background-color: transparent;
  border: 1px solid #009ce2;
  border: 1px solid var(--colorDefault);
  color: #009ce2;
  color: var(--colorDefault);
}
.hidden {
  display: none;
}

.v-center {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.containerLoading {
  position: fixed;
  z-index: 9999;
  background: rgba(250, 250, 250, 0.85);
  flex: 1 1;
  height: 100%;
  width: 100%;
  margin-left: -15px;
}

/* Checkbox Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
}
.switch-absolute {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
  right: 0;
  margin-right: 12px;
  margin-top: -8px;
  z-index: 99;
}
.switch input, .switch-absolute input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #009ce2;
  background-color: var(--colorDefault);
}
input:checked + .slider:before {
  transform: translateX(26px);
} 

.hidden-xs, .hidden-sm, .hidden-md {
  display: inline;
  display: initial;
} 
.visible-xs, .visible-sm, .visible-md {
  display: none;
} 
/* XS Mobile Layout */
@media only screen and (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
  .visible-xs {
    display: inline;
    display: initial;
  }
  h1 {
    font-size: 2.3rem;
  }
  p {
    font-size: 16px;
  }
  .btn {
    font-size: 16px;
  }
}

/* SM - Tablet Layout */
@media only screen and (min-width: 768px) and (max-width: 991px){
  .hidden-sm {
    display: none;
  }
  .visible-sm {
    display: inline;
    display: initial;
  }
}

/* MD Layout */
@media only screen and (min-width: 992px) and (max-width: 1199px){
  .hidden-md {
    display: none;
  }
  .visible-md {
    display: inline;
    display: initial;
  }
}
#cadastro {
  /*max-width: 700px;
  margin: 150px auto 0;
  max-width: 450px;
  width: 100%;
  display: flex;*/
  /*flex-direction: column;
  align-items: center;
  margin-top: 10%;*/
}
#cadastro #logo {
  width: 200px;
  margin-bottom: 40px;
}
#cadastro .content {
  /*width: 100%;*/
  margin-top: 20px;
  border-radius: 10px;
  padding: 30px;
  border: 0;
  box-shadow: 0px 0px 0 0px rgb(177, 177, 177);
  /*text-align: center;*/
  margin: 15px;
}
#cadastro .content > p {
  color: rgb(153, 153, 153);
}
#cadastro .content > h1 {
  color: rgb(109, 109, 109);
  text-transform: uppercase;
  font-size: 30px
}
#cadastro .content {
  max-width: 600px;
  width: 100%;
}
@media only screen and (max-width: 552px) {
  #cadastro #logo {
    width: 120px;
    margin-bottom: 40px;
  }
}


#cadastro {
  /*max-width: 700px;
  margin: 150px auto 0;
  max-width: 450px;
  width: 100%;
  display: flex;*/
  /*flex-direction: column;
  align-items: center;
  margin-top: 10%;*/
}
#cadastro #logo {
  width: 200px;
  margin-bottom: 40px;
}
#cadastro .content {
  /*width: 100%;*/
  margin-top: 20px;
  border-radius: 10px;
  padding: 30px;
  border: 0;
  box-shadow: 0px 0px 0 0px rgb(177, 177, 177);
  /*text-align: center;*/
  margin: 15px;
}
#cadastro .content > p {
  color: rgb(153, 153, 153);
}
#cadastro .content > h1 {
  color: rgb(109, 109, 109);
  text-transform: uppercase;
  font-size: 30px
}
#cadastro .content {
  max-width: 600px;
  width: 100%;
}
@media only screen and (max-width: 552px) {
  #cadastro #logo {
    width: 120px;
    margin-bottom: 40px;
  }
}
.barraSuperior, .barraSuperior.affix {
  background: var(--colorDefaultHover);
  color: var(--backgroundDefault);
  z-index: 999;
  padding: 8px 15px;
  box-shadow: 0px 5px 5px -2px #a8a8a8;
  --webkit-box-shadow: 0px 5px 5px -2px #a8a8a8;
}
.barraSuperior .logo {
  /*padding-left: 20px;*/
  color: var(--colorText);
}
.barraSuperior .imgMenu #btnMenu {
  cursor: pointer;
  color: var(--backgroundDefault);
}
#menuUser {
  background: var(--colorDefaultHover);
  color: var(--backgroundDefault);
  position: fixed;
  right: 0;
  top: 75px;
  height: 100%;
  z-index: 999;
  width: auto;
  padding: 0px;
  visibility: hidden;
  transform: translateX(250px);
  transition: all 0.3s ease;
  box-shadow: 0px 10px 10px -2px var(--colorText);
  --webkit-box-shadow: 0px 10px 10px -2px var(--colorText);
}
#menuUser.active {
  visibility: visible;
  transform: translateX(0px);
}
#menuUser li{
  padding: 15px 40px 15px 25px;
  list-style: none;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
}
#menuUser li.active, #menuUser li:hover{
  background: var(--colorDefault);
  color: white;
}
#menuUser li span,  #menuUser li svg{
  margin-right: 15px;
}
#menuUser li img {
  margin-top: -5px;
}
#logo-barra{
  width: 60px;
}
#titleMenu {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
}
.bodyDashboard {
  margin: 0px auto 0;
  padding-top: 15px;
  max-width: 750px;
  width: 100%;
  height: 100%;
  max-height: 92vh;
  overflow: scroll;
  overflow-x: hidden;
}
.itensHome {
  width: 100%;
  /*height: 80vh;*/
  padding-bottom: 60px;
}
.itensHome #imgUser{
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--colorDefault);
  background-color: var(--colorDefaultHover);
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}
.itensHome .title{
  margin-top: 30px;
  margin-left: 15px;
}
.itemHome {
  padding: 20px 15px;
  margin: 5px;
  box-shadow: 0px 0px 8px 0px rgb(210 210 210);
  --webkit-box-shadow: 0px 0px 8px 0px rgb(210 210 210);
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 15px;
  background: #fff;
}
.itemHome span{
  color: var(--colorText2);
}
.itemHome .itemHomeIcon{
  color: var(--colorDefault);
}
.itemHome .title{
  margin-top: 20px;
  color: var(--colorDefault);
  font-weight: bold;
  font-size: 25px;
  margin-left: 0;
}
.itemHome #imgBusiness {
  width: 280px;
  height: 200px;
  background-size: cover;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  margin: 0px;
  background-size: cover;
  background-position: center;
}
.dashboardList .itemDashboardList, .musicList .itemMusicList {
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 5px;
  padding: 15px;
  background: rgb(253, 253, 253);
  /*border: 2px solid rgb(245, 245, 245);*/
  box-shadow: 0px 2px 8px -2px rgb(177, 177, 177);
  --webkit-box-shadow: 0px 2px 8px -2px rgb(177, 177, 177);
  list-style: none;
  border-left: 3px solid var(--colorDefault);
  font-family: 'Kalam-Bold';
  font-size: 18px;
  color: var(--colorDefaultHover);
}
.userList .itemUserList {
  margin-bottom: 0px;
  margin-top: 8px;
  border-radius: 5px;
  padding: 10px 10px;
  background: rgb(253, 253, 253);
  box-shadow: 0px 2px 5px -2px rgb(177, 177, 177);
  --webkit-box-shadow: 0px 2px 5px -2px rgb(177, 177, 177);
  list-style: none;
  border-left: 3px solid var(--colorDefault);
}
.userList label#thumbnail{
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--colorDefault);
  background-color: var(--colorDefaultHover);
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}
.userList form label {
  font-size: 14px;
  color: #444;
  font-weight: bold;
  margin-bottom: 2px;
}
.dashboardList .itemDashboardListActive{
  box-shadow: 0px 0px 10px 1px #636363;
  --webkit-box-shadow: 0px 0px 10px 1px #636363;
  /*paddingbox-shadow: 0px 0px 2px 3px var(--colorDefault);
  border: 0;*/
}
.headList {
  cursor: pointer;
}
.dashboardList .itemDashboardList .iconItem, .musicList .itemMusicList .iconItem{
  margin: 5px;
  color: dimgrey;
}
.dashboardList .itemDashboardList .edit{
  color: #01d401;
}
.dashboardList .itemDashboardList .delete{
  color: #888888;
}
.dashboardList .itemDashboardList .itemImg {
  width: 100%;
  max-width: 160px;
}
.dashboardList .itemDashboardList label#thumbnail, label#thumbnailBusiness{
  display: flex;
  width:100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ddd;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.dashboardList .itemDashboardList label#background{
  width:100%;
  height: 350px;
  margin-bottom: 1rem;
  border: 1px dashed #ddd;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.dashboardList .itemDashboardList .itemDashboardListSwitch {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 15px;
  margin-top: -10px;
  z-index: 10;
}
.dashboardEditbusiness {
  margin-bottom: 15px;
  margin-top: 20px;
  list-style: none;
  padding: 15px 0px;
}
.linkListItem {
  text-decoration: underline;
  font-weight: bold;
  margin-left: 20px;
  margin-top: -15px;
}
/*.musicList .itemMusicList label#thumbnail {
  width: 100%;
  height: 250px;
  margin-bottom: 1rem;
  border: 1px dashed #ddd;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}*/
label#thumbnail input, label#background input, label#imageShare input, label#thumbnailBusiness input {
  display: none;
}
label#thumbnail.has-thumbnail, label#background.has-thumbnail, label#imageShare.has-thumbnail, label#thumbnailBusiness.has-thumbnail {
  border: 0 !important;
}
label#thumbnail img, label#background img, label#imageShare img {
  /*top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -100%);*/
}
label#thumbnail.has-thumbnail img, label#background.has-thumbnail img, label#imageShare.has-thumbnail img, label#thumbnailBusiness.has-thumbnail img{
  display: none;
}
.title {
  color: var(--colorText);
  /*font-weight: 700;*/
  font-family: 'Kalam-Bold';
  font-size: 24px;
  width: 100%;
}
.labelFilter {
  color: var(--colorText);
  font-weight: 500;
  font-size: 16px;
  margin-left: 8px;
  margin-right: 8px;
}
.iconFilter {
  color: var(--colorText);
}
.btnNew {
  margin: 5px 0px;
  border-radius: 50%;
  border: none;
  padding: 5px;
  width: 50px;
  height: 50px;
  background: #ffffff00;
  color: var(--colorDefault);
  box-shadow: 0px 2px 10px -2px #bfbfbf;
  --webkit-box-shadow: 0px 2px 10px -2px #bfbfbf;
}
.btnDeleteImg {
  position: absolute;
  margin-top: 92px;
  margin-left: 27px;
  border-radius: 50%;
  border: none;
  padding: 5px;
  width: 30px;
  height: 30px;
  background: #f8f8f8;
  color: var(--colorDefault);
  box-shadow: 0px 2px 10px -2px #bfbfbf;
  --webkit-box-shadow: 0px 2px 10px -2px #bfbfbf;
}
.iconInputRight{
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 25px;
  margin-top: 13px;
  color: #ababab;
}
.contentEditor {
  border: 1px solid #ced4da;
  border-radius: .25rem;
}
.contentEditor .inputEditor {
  padding: 15px;
}
.imagesPreview {
  display: inline-block;
  width: 130px;
  height: 130px;
  margin: 10px;
  border-radius: 10px;
  background-size: cover !important;
  background-position: center !important;
  cursor: pointer;
}
#labelImages {
  cursor: pointer;
}
#formNotification{
  width: 100%;
}
.emotionSelected {
  margin-bottom: 5px;
  margin-left: 7px;
  padding: 4px;
  background-color: #ddd;
  border-radius: 5px;
  /*width: 80px;*/
}
.emotionSelected .btnDeleteEmotion {
  margin-left: 8px;
  cursor: pointer;
}
.itemSelected {
  margin-top: -5px;
  margin-bottom: 12px;
  margin-left: 15px;
  padding: 8px 30px 8px 20px;
  background-color: #ddd;
  border-radius: 5px;
  position: relative;
  font-weight: 600;
  /*border: 2px solid var(--colorDefault);
  padding: 10px 25px 10px 20px;
  font-size: 14px;*/
}
.itemSelected .btnDeleteItemSelected {
  margin-left: 12px;
  cursor: pointer;
  color: var(--colorText);
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 5px;
}
.itemFinance {
  padding: 12px;
  /*margin: 0px -8px;*/
  border-radius: 6px;
  margin-bottom: 12px;
  padding-bottom: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px -1px rgb(176 176 176);
  --webkit-box-shadow: 0px 0px 10px -1px rgb(176 176 176);
  border-left: 6px solid var(--colorDefault);
}
.itemFinance .financeLabel {
  /*color: #707070;*/
}
.itemFinance .financeValue {
  font-size: 28px;
  font-family: 'Kalam-Bold';
  color: #3f3f3f;
}
.itemFinance.disponivel{
  border-left: 6px solid var(--secondColor);
} 
.itemFinance.liberar{
  border-left: 6px solid var(--colorDefault);
}
.itemFinance.emAberto{
  border-left: 6px solid var(--thirdColor);
}
.itemFinance.bloqueado{
  border-left: 6px solid red;
}
.itemFinance .btn-transfer {
  background-color: transparent;
  border: 1px solid var(--secondColor);
  color: var(--secondColor);
  padding: 6px 15px;
  border-radius: 6px;
  padding-top: 8px;
  font-size: 16px;
}
.listTransactions {
  margin-top: 15px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.itemLegenda {
  font-size: 12px;
  display: inline;
  margin-right: 8px;
}
.iconLegenda {
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-right: 0px;
  border-radius: 2px;
}
/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
  /*#logo-barra{
    width: 60px;
  }*/
  .dashboardList .itemDashboardList label#thumbnail, label#thumbnailBusiness {
    width:100%;
    height: 160px;
  }
  .itensHome {
    height: auto;
  }
  .itemHome #imgBusiness {
    width: 100%;
    height: 200px;
    border-radius: 0px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .title {
    font-size: 22px;
  }
  #titleMenu {
    font-size: 14px;
  }
}
/* Scrollbar*/
::-webkit-scrollbar { width: 6px;  }/* for vertical scrollbars */
::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.0); }
::-webkit-scrollbar-thumb { background: rgb(185, 185, 185) }

#homeIndex h1, #homeIndex h2, #homeIndex h3, #homeIndex h4, #homeIndex h5, #homeIndex h6, #homeIndex p{
  color: var(--colorText);
  color: #4b4b4b;
}
#menu {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgb(0, 0, 0, 0.9);
  height: 100vh;
  width: 100%;
  z-index: 99;
}
#menu li {
  margin-bottom: 12px;
}
#menu li a {
  font-size: 28px;
  color: white;
  /*font-family: "Kalam-Bold";*/
}
.btnMenu {
  padding: 8px 12px;
  z-index: 9999;
  position: inherit;
}
.btnMenu i{
  margin-top: 5px;
  font-size: 1.5em;
}
#homeIndex {
  /*background-image: url('../../assets/background.jpg');
  background: var(--colorDefault);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  padding-top: 30px;*/
}

#homeIndex #navbar{
  padding: 12px 0;
}

#homeIndex #navbar.onScroll{
  padding: 0px;
  position: fixed;
  width: 100%;
  background: #f8f8f8;
  z-index: 99;
  box-shadow: 0px 0px 5px -1px grey;
}

#homeIndex .logo img{
  max-width: 120px;
}

#homeIndex #navbar.onScroll .logo img{
  max-width: 80px;
}

/*#homeIndex a {
  color: var(--colorDefault);
  text-transform: uppercase;
  line-height: normal;
  font-weight: bold;
}*/

#homeIndex .bodyIndex {
  padding: 60px 0;
  height: 80vh;
  place-content: stretch;
  background: var(--backgroundDefault);
  /*border-top-right-radius: 50px;
  border-top-left-radius: 50px;*/
}

#homeIndex #navbar a {
  font-family: 'Kalam-Bold';
  text-transform: none;
  font-size: 20px;
  padding: 12px 20px;
  color: var(--colorText);
}

#homeIndex #navbar a:hover {
  color: var(--colorDefault);
}

#homeIndex #navbar a.btn-default{
  color: #fff;
}

#homeIndex .bodyIndex p {
  margin-top: 30px;
  font-size: 20px;
}

#homeIndex .bodyIndex .card p{
  color: #004db9;
  margin-bottom: 0;
  padding: 15px;
  font-weight: 600;
  font-size: 18px;
}

#homeIndex .bodyIndex button {
  padding: 12px 35px;
}
#homeIndex p {
  font-size: 16px;
}
#homeIndex .footerIndex {
  padding: 15px 0;
  background: #f8f8f8;
}
#homeIndex .footerIndex p, #homeIndex .footerIndex a {
  color: #353535;
  text-transform: none;
  margin-bottom: 8px;
}

#bodyHowWorks, #bodyKnow, #bodyTax {
  padding: 80px 0;
  margin-top: -100px;
}
#bodyHowWorks .title, #bodyKnow .title, #bodyTax .title {
 margin-top: 15px;
 font-size: 20px;
}
#bodyHowWorks img {
  padding: 30px;
}
#bodyKnow #imageDemo {
  margin-bottom: 15px;
  box-shadow: 0px 0px 10px 1px #dbdbdb;
  border-radius: 12px;
}
#bodyKnow .itemKnow {
  box-shadow: 0px 0px 10px 1px #dbdbdb;
  padding: 30px 15px;
  border-radius: 8px;
  background: white;
  margin-bottom: 15px;
}
#bodyKnow .itemKnow .title{
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 5px;
}
#bodyKnow .itemKnow span{
  font-size: 14px;
}
#bodyKnow .itemKnow i{
  color: var(--colorDefault);
  font-size: 2.5em;
}
#bodyTax .itemTax {
  box-shadow: 0px 0px 10px 1px #dbdbdb;
  padding: 25px 15px;
  border-radius: 8px;
  background: white;
  margin-bottom: 15px;
  border: 3px solid #fff;
}
#bodyTax .itemTax .priceItemTax {
  background: var(--colorDefault);
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: inline-flex;
}
#bodyTax .itemTax .priceItemTax p{
  color: #fff;
  font-size: 2.0em;
}
#bodyTax .itemTax .priceItemTax i {
  color: #fff;
  font-size: 28px;
}
#bodyTax .itemTax:hover, #bodyTax .itemTax.active {
  border: 3px solid #009ce2;
}
.lineDivider {
  width: 100%;
  height: 320px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -50px;
}

/* Mobile Layout */
@media only screen and (max-width: 767px) {
  #homeIndex .bodyIndex {
    padding: 20px 0;
    height: auto;
  }
  #homeIndex .logo img{
    max-width: 80px;
  }
  .itensHome {
    height: auto;
  }
  .lineDivider {
    width: 100%;
    height: 200px;
    background-image: none !important;
  }
  /*#homeIndex a {
    color: white;
    text-transform: uppercase;
    font-size: 10px;
  }*/
}
/* Scrollbar*/
::-webkit-scrollbar { width: 6px;  }/* for vertical scrollbars */
::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.0); }
::-webkit-scrollbar-thumb { background: rgb(185, 185, 185) }

#homeIndex h1, #homeIndex h2, #homeIndex h3, #homeIndex h4, #homeIndex h5, #homeIndex h6, #homeIndex p{
  color: var(--colorText);
  color: #4b4b4b;
}

#homeLive {
  /*background-image: url('../../assets/background.jpg');
  background: var(--colorDefault);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  padding-top: 30px;*/
}
#bannerChannel {
  background-image: url(/static/media/banner.0879a002.jpg);
  width: 100%;
  height: 100%;
  max-height: 250px;
  min-height: 160px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  z-index: 9;
}
#bodyLive {
  padding-top: 20px;
  /*padding-bottom: 200px;*/
}
#bodyLive .headerChat {
  background: #f0f0f0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 8px 12px;
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
  margin-bottom: -40px;
  margin-top: 30px;
  z-index: 9;
  box-shadow: 0px 2px 10px -2px #ababab;
}
#bodyLive #chat {
  width: 100%;
  min-height: 80vh;
  border-radius: 10px;
  box-shadow: 0px 0px 10px -2px #ababab;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #efefef;
  background-image: url(/static/media/fundo-chat.e5342b51.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 150px;
  max-height: 90vh;
  overflow: auto;
  padding-top: 10px;
}
#bodyLive #imageUser {
  background-image: url(/static/media/icon-profile.5ed0a5fa.png);
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-right: 15px;
  /*box-shadow: 0px 2px 10px -2px #ababab;*/
  background-color: #fff;
}
#bodyLive #chat .messageSend {
  position: relative;
  background: #d8d8d8;
  border-radius: 8px;
  padding: 8px 8px;
  max-width: 40%;
  min-width: 40%;
  padding: 10px;
  margin-bottom: 15px;
  z-index: 1;
}
#bodyLive #chat .messageEdit {
  position: relative;
  background: #d8d8d8;
  border-radius: 8px;
  padding: 8px 8px;
  max-width: 40%;
  min-width: 40%;
  padding: 10px;
  margin-bottom: 15px;
  z-index: 1;
  border-bottom: 1px dashed;
  border-left: 1px dashed;
  border-right: 1px dashed;
}
#bodyLive #chat .messageSend::after {
  content: " ";
  position: absolute;
  left: -10px;
  top: 5px;
  border-top: 15px solid transparent;
  border-right: 15px solid #d8d8d8;
  border-left: none;
  border-bottom: 15px solid transparent;
}
#bodyLive #chat .messageEdit::after {
  content: " ";
  position: absolute;
  right: -10px;
  top: 8px;
  border-top: 15px solid transparent;
  border-right: none;
  border-left: 15px solid #d8d8d8;
  border-bottom: 15px solid transparent;
}
#bodyLive #chat .messageSend.bg-blue, #bodyLive #chat .messageEdit.bg-blue {
  background: #009ce2;
}
#bodyLive #chat .messageSend.bg-blue::after {
  border-left: none;
  border-right: 15px solid #009ce2;
}
#bodyLive #chat .messageEdit.bg-blue::after {
  border-left: 15px solid #009ce2;
  border-right: none;
}
#bodyLive #chat .messageSend.bg-pink, #bodyLive #chat .messageEdit.bg-pink {
  background: #ff32aa;
}
#bodyLive #chat .messageSend.bg-pink::after {
  border-left: none;
  border-right: 15px solid #ff32aa;
}
#bodyLive #chat .messageEdit.bg-pink::after {
  border-left: 15px solid #ff32aa;
  border-right: none;
}
#bodyLive #chat .messageSend.bg-purple, #bodyLive #chat .messageEdit.bg-purple {
  background: #d800e2;
}
#bodyLive #chat .messageSend.bg-purple::after {
  border-left: none;
  border-right: 15px solid #d800e2;
}
 #bodyLive #chat .messageEdit.bg-purple::after {
  border-left: 15px solid #d800e2;
  border-right: none;
}
#bodyLive #chat .messageSend.bg-yellow, #bodyLive #chat .messageEdit.bg-yellow {
  background: #faec05;
}
#bodyLive #chat .messageSend.bg-yellow::after {
  border-left: none;
  border-right: 15px solid #faec05;
}
#bodyLive #chat .messageEdit.bg-yellow::after {
  border-left: 15px solid #faec05;
  border-right: none;
}
#bodyLive #chat .messageSend.bg-green, #bodyLive #chat .messageEdit.bg-green {
  background: #00e29d;
}
#bodyLive #chat .messageSend.bg-green::after {
  border-left: none;
  border-right: 15px solid #00e29d;
}
#bodyLive #chat .messageEdit.bg-green::after {
  border-left: 15px solid #00e29d; 
  border-right: none;
}
#bodyLive #chat .messageUser {
  font-family: 'Kalam-Bold';
}
#bodyLive #chat .messageValue {
  font-family: 'Kalam-Bold';
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px 8px;
}
#bodyLive #chat .message {
  margin-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
}
#bodyLive #chat .messageHour {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 8px;
  font-size: 12px;
}
#bodyLive #chat .messageStatus {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: -25px;
  padding-top: 2px;
  border-top: 1px dashed;
  border-left: 1px dashed;
  border-right: 1px dashed;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
  /* border-radius: 5px; */
  /* height: 100%; */
  /* color: white; */
  /* justify-content: center; */
  /* display: flex; */
  /* align-items: end;*/
}
#bodyLive #chat .messageStatus i{
  font-size: 14px;
}
#bodyLive #chat .messageImage {
  width: 100%;
  min-height: 250px;
  max-height: 400px;
  margin-top: 10px;
  border-radius: 8px;
}
#bodyLive #chat .messageImage label{
  width: 100%;
  height: 100%;
  min-height: 250px;
  max-height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  margin-bottom: 0px;
  background-size: contain;
  /*border: 2px solid black;*/
  background-color: #000000;
}
#bodyLive #chat .messageVideo {
  width: 100%;
  min-height: 250px;
  max-height: 400px;
  margin-top: 10px;
  border-radius: 8px;
}
#bodyLive #chat .messageVideo video{
  width: 100%;
  height: 100%;
  min-height: 250px;
  max-height: 400px;
  border-radius: 8px;
  margin-bottom: 0px;
  /*border: 2px solid black;*/
  background-color: #000000;
}
#bodyLive #chat .btnDeleteFile {
  position: absolute;
  right: 0;
  margin: 5px 15px;
  color: #000000;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  box-shadow: 0px 0px 5px -2px #d3d3d3;
  border: 0;
  z-index: 9;
  cursor: pointer;
}
#bodyLive #chat .btn-pay-message {
  padding: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  border: 0;
  font-size: 16px;
  color: black;
  border: 2px solid black;
}
#bodyLive #chat .btn-pay-message:hover{
  background: #eeeeee;
}
#bodyLive .divImageChannel {
  width: 13%;
  padding-left: 15px;
}
#bodyLive .divNameChannel {
  width: 85%;
  /* margin-left: 15px; */
  padding-top: 12px;
  /* padding-bottom: 12px; */
}
#bodyLive #imageChannel {
  background-image: url(/static/media/logo-white.97a756c2.png);
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  box-shadow: 0px 2px 10px -2px #ababab;
}
#footerLive {
  position: fixed;
  bottom: 0;
  width: inherit;
  max-width: inherit;
  box-shadow: 0px -2px 10px -2px #ababab;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 10px;
  background: #f0f0f0;
  z-index: 999;
}
#footerLive .div-input-comment {
  width: 90%; 
  position: relative;
}
#footerLive .form-control {
  font-family: 'Kalam-Regular';
  height: 55px;
  border: 0;
  border-radius: 30px;
  box-shadow: 0px 0px 5px -2px #d3d3d3;
  padding: 0.6em 4.8em 0.5em 0.8em;
}
#footerLive .div-btn-send {
  width: 10%; 
  justify-content: center;
}
#footerLive .btn-send {
  margin: 0px 15px;
  color: var(--colorDefault);
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  display: flex;
  box-shadow: 0px 0px 5px -2px #d3d3d3;
  border: 0;
}
#footerLive .btn-send i {
  font-size: 1.5em;
  margin-left: -3px;
}
#footerLive .btn-input-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 15px;
  height: 55px;
  color: #848484;
}
#footerLive .btn-input-comment i {
  font-size: 1.3em;
}
#footerLive .btn-input-comment .modal-emogi, .emoji-mart {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -360px;
  margin-right: -60px;
  box-shadow: 0px 0px 15px -1px #b8b8b8;
}
#footerLive .image-upload[type="file"] {
  display: none;
}
#footerLive .menu-upload {
  width: 100px;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: 0px 0px 15px -1px #b8b8b8;
  padding: 8px;
  margin-top: -55px;
  border-radius: 5px;
}
#footerLive label{
  margin: 5px 10px;
  color: var(--colorDefaultHover)
}
#footerLive #inputValue {
  border: 0;
  /*width: 150px;*/
  background: transparent;
  text-align: center;
  font-size: 35px;
}
#footerLive .inputValueLabel {
  font-size: 30px;
  margin-top: 10px;
}
#footerLive input[type="range"] {
  width: 100%;
  max-width: 600px;
  /*margin-bottom: 15px*/
}
/* Mobile Layout: 320px. */
@media only screen and (max-width: 552px) {
  #bannerChannel {
    max-height: 120px;
    min-height: 80px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: 0px 2px 10px -2px #ababab;
  }
  #bodyLive {
    /*padding-top: 0px;
    padding-bottom: 200px;*/
  }
  #bodyLive .headerChat {
    /*border-top-left-radius: 0px;
    border-top-right-radius: 0px;*/
    padding: 8px 10px 0px 10px;
  }
  #bodyLive #chat .messageSend, #bodyLive #chat .messageEdit {
    max-width: 80%;
    min-width: 80%;
  }
  #bodyLive .divImageChannel {
    width: 30%;
    padding-left: 15px;
  }
  #bodyLive .divNameChannel {
    width: 70%;
  }
  #bodyLive #imageChannel {
    width: 80px;
    height: 80px;
  }
  #footerLive {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 5px 0px 10px 0px;
    /* padding-left: 10px; */
  }
  #footerLive .div-input-comment {
    width: 80%; 
    padding-left: 10px;
  }
  #footerLive .div-btn-send {
    width: 20%; 
    justify-content: center;
  }
  #footerLive .btn-send {
    margin: 0px 5px;
    color: var(--colorDefault);
    /*width: 50px;
    height: 50px;*/
    display: flex;
    box-shadow: 0px 0px 5px -2px #d3d3d3;
  }
  #footerLive .btn-send i {
    font-size: 1.5em;
  }  
}

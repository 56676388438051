@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
/* Variáveis*/
:root {
  --colorDefault: #009ce2;
  --colorDefaultHover: #0080bd;
  --secondColor: #2ac705;
  --thirdColor: #fde122;
  --colorText: #4b4b4b;
  --colorText2: #919191;
  --backgroundDefault: #f8f8f8;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

/* Scrollbar*/
::-webkit-scrollbar { width: 6px;  }/* for vertical scrollbars */
::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.0); }
::-webkit-scrollbar-thumb { background: var(--colorDefault); }

@font-face {
  font-family: 'Kalam-Regular';
  font-size: 18px;
  src: local('Kalam-Regular'), url(./assets/fonts/Kalam-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Kalam-Bold';
  src: local('Kalam-Bold'), url(./assets/fonts/Kalam-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Chewy-Regular';
  src: local('Chewy-Regular'), url(./assets/fonts/Chewy-Regular.ttf) format('truetype');
}
.color-primary {
  color: var(--colorDefault);
}
.bg-primary {
  background-color: var(--colorDefault);
}
.color-second {
  color: var(--secondColor);
}
.bg-second {
  background-color: var(--secondColor);
}
.color-third {
  color: var(--thirdColor);
}
.bg-third {
  background-color: var(--thirdColor);
}

html, body, #root {
  min-height: 100%;;
  font-family: 'Kalam-Regular';
}

body {
  background: #f8f8f8;
  background-size: cover;
  -webkit-font-smoothing: antialiased !important;
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: none !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Kalam-Bold';
}
ul {
  list-style: none;
}
p {
  font-size: 18px;
}
a {
  color: var(--colorDefault);
}
a:hover {
  text-decoration: none;
  color: var(--colorDefaultHover);
}
.btn {
  font-size: 18px;
}
.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: none !important;
  --webkit-box-shadow: none !important;
}
.btn-input {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  margin-top: -68px;
  margin-right: 25px;
  background: white;
  /* padding: 5px; */
  border-radius: 50%;
  width: 45px;
  height: 45px;
  box-shadow: 0px 0px 5px -2px #d3d3d3;
  --webkit-box-shadow: 0px 0px 5px -2px #d3d3d3;
}
.btn-input i {
  font-size: 1.2em !important;
}
/*body, input, button {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 14px;
}*/
.form-control {
  /*padding-top: 25px;*/
  font-family: 'Kalam-Regular';
  height: 60px;
  padding: 2em 0.8rem 0.8em 0.8rem;
}
.form-control:focus {
  color: var(--colorText);
  background-color: #fff;
  border-color: #c7c7c7;
  outline: 0;
  box-shadow: none;
  --webkit-box-shadow: none;
}
button:focus {
  outline: none;
}
form {
  display: flex;
  flex-direction: column;
}
form label {
  font-size: 14px;
  color: #444;
  font-weight: bold;
  margin-bottom: 8px;
}
.labelInput {
  position: absolute;
  margin-left: 10px;
  margin-top: 2px;
  /*font-size: 12px;*/
}
.labelImage {
  font-size: 14px;
  background: white;
  /*float: left;*/
}
form label span {
  font-weight: normal;
  color: #999;
  font-size: 12px;
}
form input {
  border: 1px solid #ddd;
}
.btn {
  border: 0;
  border-radius: 5px;
  font-weight: bold;
  background: var(--colorDefault);
  color: #fff;
  cursor: pointer;
  padding: 12px 15px;
}
.btn-default {
  background: var(--colorDefault);
  color: #fff;
}
.btn-default:hover {
  background: var(--colorDefaultHover);
  color: #fff;
}
.btn-default-outline {
  padding: 15px 22px;
  border-radius: 30px;
  background: var(--backgroundDefault);
  border: 2px solid var(--colorDefault);
  color: var(--colorDefault);
}
.btn-default-outline:hover {
  background: var(--colorDefault);
  color: #fff;
}
.btn-delete {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}
.btn-delete:hover {
  background: #1d1d1d;
  border-color: #1d1d1d;
  color: #fff;
}
.btnLink {
  color: var(--colorDefault);
  cursor: pointer;
  border: 0;
  background: transparent;
}
.btnCloseModal {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px;
  z-index: 99;
  color: #2b2b2b;
  color: var(--colorDefault);
  padding: 5px;
  font-size: 25px;
  border: 0;
  background: transparent;
}
.btn-outline {
  background-color: transparent;
  border: 1px solid var(--colorDefault);
  color: var(--colorDefault);
}
.hidden {
  display: none;
}

.v-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.containerLoading {
  position: fixed;
  z-index: 9999;
  background: rgba(250, 250, 250, 0.85);
  flex: 1;
  height: 100%;
  width: 100%;
  margin-left: -15px;
}

/* Checkbox Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
}
.switch-absolute {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
  right: 0;
  margin-right: 12px;
  margin-top: -8px;
  z-index: 99;
}
.switch input, .switch-absolute input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: var(--colorDefault);
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
} 

.hidden-xs, .hidden-sm, .hidden-md {
  display: initial;
} 
.visible-xs, .visible-sm, .visible-md {
  display: none;
} 
/* XS Mobile Layout */
@media only screen and (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
  .visible-xs {
    display: initial;
  }
  h1 {
    font-size: 2.3rem;
  }
  p {
    font-size: 16px;
  }
  .btn {
    font-size: 16px;
  }
}

/* SM - Tablet Layout */
@media only screen and (min-width: 768px) and (max-width: 991px){
  .hidden-sm {
    display: none;
  }
  .visible-sm {
    display: initial;
  }
}

/* MD Layout */
@media only screen and (min-width: 992px) and (max-width: 1199px){
  .hidden-md {
    display: none;
  }
  .visible-md {
    display: initial;
  }
}
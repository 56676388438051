/* Scrollbar*/
::-webkit-scrollbar { width: 6px;  }/* for vertical scrollbars */
::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.0); }
::-webkit-scrollbar-thumb { background: rgb(185, 185, 185) }

#homeIndex h1, #homeIndex h2, #homeIndex h3, #homeIndex h4, #homeIndex h5, #homeIndex h6, #homeIndex p{
  color: var(--colorText);
  color: #4b4b4b;
}

#homeLive {
  /*background-image: url('../../assets/background.jpg');
  background: var(--colorDefault);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  padding-top: 30px;*/
}
#bannerChannel {
  background-image: url('../../assets/banner.jpg');
  width: 100%;
  height: 100%;
  max-height: 250px;
  min-height: 160px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  z-index: 9;
}
#bodyLive {
  padding-top: 20px;
  /*padding-bottom: 200px;*/
}
#bodyLive .headerChat {
  background: #f0f0f0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 8px 12px;
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
  margin-bottom: -40px;
  margin-top: 30px;
  z-index: 9;
  box-shadow: 0px 2px 10px -2px #ababab;
}
#bodyLive #chat {
  width: 100%;
  min-height: 80vh;
  border-radius: 10px;
  box-shadow: 0px 0px 10px -2px #ababab;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #efefef;
  background-image: url('../../assets/fundo-chat.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 150px;
  max-height: 90vh;
  overflow: auto;
  padding-top: 10px;
}
#bodyLive #imageUser {
  background-image: url('../../assets/icons/icon-profile.png');
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-right: 15px;
  /*box-shadow: 0px 2px 10px -2px #ababab;*/
  background-color: #fff;
}
#bodyLive #chat .messageSend {
  position: relative;
  background: #d8d8d8;
  border-radius: 8px;
  padding: 8px 8px;
  max-width: 40%;
  min-width: 40%;
  padding: 10px;
  margin-bottom: 15px;
  z-index: 1;
}
#bodyLive #chat .messageEdit {
  position: relative;
  background: #d8d8d8;
  border-radius: 8px;
  padding: 8px 8px;
  max-width: 40%;
  min-width: 40%;
  padding: 10px;
  margin-bottom: 15px;
  z-index: 1;
  border-bottom: 1px dashed;
  border-left: 1px dashed;
  border-right: 1px dashed;
}
#bodyLive #chat .messageSend::after {
  content: " ";
  position: absolute;
  left: -10px;
  top: 5px;
  border-top: 15px solid transparent;
  border-right: 15px solid #d8d8d8;
  border-left: none;
  border-bottom: 15px solid transparent;
}
#bodyLive #chat .messageEdit::after {
  content: " ";
  position: absolute;
  right: -10px;
  top: 8px;
  border-top: 15px solid transparent;
  border-right: none;
  border-left: 15px solid #d8d8d8;
  border-bottom: 15px solid transparent;
}
#bodyLive #chat .messageSend.bg-blue, #bodyLive #chat .messageEdit.bg-blue {
  background: #009ce2;
}
#bodyLive #chat .messageSend.bg-blue::after {
  border-left: none;
  border-right: 15px solid #009ce2;
}
#bodyLive #chat .messageEdit.bg-blue::after {
  border-left: 15px solid #009ce2;
  border-right: none;
}
#bodyLive #chat .messageSend.bg-pink, #bodyLive #chat .messageEdit.bg-pink {
  background: #ff32aa;
}
#bodyLive #chat .messageSend.bg-pink::after {
  border-left: none;
  border-right: 15px solid #ff32aa;
}
#bodyLive #chat .messageEdit.bg-pink::after {
  border-left: 15px solid #ff32aa;
  border-right: none;
}
#bodyLive #chat .messageSend.bg-purple, #bodyLive #chat .messageEdit.bg-purple {
  background: #d800e2;
}
#bodyLive #chat .messageSend.bg-purple::after {
  border-left: none;
  border-right: 15px solid #d800e2;
}
 #bodyLive #chat .messageEdit.bg-purple::after {
  border-left: 15px solid #d800e2;
  border-right: none;
}
#bodyLive #chat .messageSend.bg-yellow, #bodyLive #chat .messageEdit.bg-yellow {
  background: #faec05;
}
#bodyLive #chat .messageSend.bg-yellow::after {
  border-left: none;
  border-right: 15px solid #faec05;
}
#bodyLive #chat .messageEdit.bg-yellow::after {
  border-left: 15px solid #faec05;
  border-right: none;
}
#bodyLive #chat .messageSend.bg-green, #bodyLive #chat .messageEdit.bg-green {
  background: #00e29d;
}
#bodyLive #chat .messageSend.bg-green::after {
  border-left: none;
  border-right: 15px solid #00e29d;
}
#bodyLive #chat .messageEdit.bg-green::after {
  border-left: 15px solid #00e29d; 
  border-right: none;
}
#bodyLive #chat .messageUser {
  font-family: 'Kalam-Bold';
}
#bodyLive #chat .messageValue {
  font-family: 'Kalam-Bold';
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px 8px;
}
#bodyLive #chat .message {
  margin-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
}
#bodyLive #chat .messageHour {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 8px;
  font-size: 12px;
}
#bodyLive #chat .messageStatus {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: -25px;
  padding-top: 2px;
  border-top: 1px dashed;
  border-left: 1px dashed;
  border-right: 1px dashed;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
  /* border-radius: 5px; */
  /* height: 100%; */
  /* color: white; */
  /* justify-content: center; */
  /* display: flex; */
  /* align-items: end;*/
}
#bodyLive #chat .messageStatus i{
  font-size: 14px;
}
#bodyLive #chat .messageImage {
  width: 100%;
  min-height: 250px;
  max-height: 400px;
  margin-top: 10px;
  border-radius: 8px;
}
#bodyLive #chat .messageImage label{
  width: 100%;
  height: 100%;
  min-height: 250px;
  max-height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  margin-bottom: 0px;
  background-size: contain;
  /*border: 2px solid black;*/
  background-color: #000000;
}
#bodyLive #chat .messageVideo {
  width: 100%;
  min-height: 250px;
  max-height: 400px;
  margin-top: 10px;
  border-radius: 8px;
}
#bodyLive #chat .messageVideo video{
  width: 100%;
  height: 100%;
  min-height: 250px;
  max-height: 400px;
  border-radius: 8px;
  margin-bottom: 0px;
  /*border: 2px solid black;*/
  background-color: #000000;
}
#bodyLive #chat .btnDeleteFile {
  position: absolute;
  right: 0;
  margin: 5px 15px;
  color: #000000;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  box-shadow: 0px 0px 5px -2px #d3d3d3;
  border: 0;
  z-index: 9;
  cursor: pointer;
}
#bodyLive #chat .btn-pay-message {
  padding: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  border: 0;
  font-size: 16px;
  color: black;
  border: 2px solid black;
}
#bodyLive #chat .btn-pay-message:hover{
  background: #eeeeee;
}
#bodyLive .divImageChannel {
  width: 13%;
  padding-left: 15px;
}
#bodyLive .divNameChannel {
  width: 85%;
  /* margin-left: 15px; */
  padding-top: 12px;
  /* padding-bottom: 12px; */
}
#bodyLive #imageChannel {
  background-image: url('../../assets/logo-white.png');
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  box-shadow: 0px 2px 10px -2px #ababab;
}
#footerLive {
  position: fixed;
  bottom: 0;
  width: inherit;
  max-width: inherit;
  box-shadow: 0px -2px 10px -2px #ababab;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 10px;
  background: #f0f0f0;
  z-index: 999;
}
#footerLive .div-input-comment {
  width: 90%; 
  position: relative;
}
#footerLive .form-control {
  font-family: 'Kalam-Regular';
  height: 55px;
  border: 0;
  border-radius: 30px;
  box-shadow: 0px 0px 5px -2px #d3d3d3;
  padding: 0.6em 4.8em 0.5em 0.8em;
}
#footerLive .div-btn-send {
  width: 10%; 
  justify-content: center;
}
#footerLive .btn-send {
  margin: 0px 15px;
  color: var(--colorDefault);
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  display: flex;
  box-shadow: 0px 0px 5px -2px #d3d3d3;
  border: 0;
}
#footerLive .btn-send i {
  font-size: 1.5em;
  margin-left: -3px;
}
#footerLive .btn-input-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 15px;
  height: 55px;
  color: #848484;
}
#footerLive .btn-input-comment i {
  font-size: 1.3em;
}
#footerLive .btn-input-comment .modal-emogi, .emoji-mart {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -360px;
  margin-right: -60px;
  box-shadow: 0px 0px 15px -1px #b8b8b8;
}
#footerLive .image-upload[type="file"] {
  display: none;
}
#footerLive .menu-upload {
  width: 100px;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: 0px 0px 15px -1px #b8b8b8;
  padding: 8px;
  margin-top: -55px;
  border-radius: 5px;
}
#footerLive label{
  margin: 5px 10px;
  color: var(--colorDefaultHover)
}
#footerLive #inputValue {
  border: 0;
  /*width: 150px;*/
  background: transparent;
  text-align: center;
  font-size: 35px;
}
#footerLive .inputValueLabel {
  font-size: 30px;
  margin-top: 10px;
}
#footerLive input[type="range"] {
  width: 100%;
  max-width: 600px;
  /*margin-bottom: 15px*/
}
/* Mobile Layout: 320px. */
@media only screen and (max-width: 552px) {
  #bannerChannel {
    max-height: 120px;
    min-height: 80px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: 0px 2px 10px -2px #ababab;
  }
  #bodyLive {
    /*padding-top: 0px;
    padding-bottom: 200px;*/
  }
  #bodyLive .headerChat {
    /*border-top-left-radius: 0px;
    border-top-right-radius: 0px;*/
    padding: 8px 10px 0px 10px;
  }
  #bodyLive #chat .messageSend, #bodyLive #chat .messageEdit {
    max-width: 80%;
    min-width: 80%;
  }
  #bodyLive .divImageChannel {
    width: 30%;
    padding-left: 15px;
  }
  #bodyLive .divNameChannel {
    width: 70%;
  }
  #bodyLive #imageChannel {
    width: 80px;
    height: 80px;
  }
  #footerLive {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 5px 0px 10px 0px;
    /* padding-left: 10px; */
  }
  #footerLive .div-input-comment {
    width: 80%; 
    padding-left: 10px;
  }
  #footerLive .div-btn-send {
    width: 20%; 
    justify-content: center;
  }
  #footerLive .btn-send {
    margin: 0px 5px;
    color: var(--colorDefault);
    /*width: 50px;
    height: 50px;*/
    display: flex;
    box-shadow: 0px 0px 5px -2px #d3d3d3;
  }
  #footerLive .btn-send i {
    font-size: 1.5em;
  }  
}
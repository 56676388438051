/* Scrollbar*/
::-webkit-scrollbar { width: 6px;  }/* for vertical scrollbars */
::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.0); }
::-webkit-scrollbar-thumb { background: rgb(185, 185, 185) }

#homeIndex h1, #homeIndex h2, #homeIndex h3, #homeIndex h4, #homeIndex h5, #homeIndex h6, #homeIndex p{
  color: var(--colorText);
  color: #4b4b4b;
}
#menu {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgb(0, 0, 0, 0.9);
  height: 100vh;
  width: 100%;
  z-index: 99;
}
#menu li {
  margin-bottom: 12px;
}
#menu li a {
  font-size: 28px;
  color: white;
  /*font-family: "Kalam-Bold";*/
}
.btnMenu {
  padding: 8px 12px;
  z-index: 9999;
  position: inherit;
}
.btnMenu i{
  margin-top: 5px;
  font-size: 1.5em;
}
#homeIndex {
  /*background-image: url('../../assets/background.jpg');
  background: var(--colorDefault);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  padding-top: 30px;*/
}

#homeIndex #navbar{
  padding: 12px 0;
}

#homeIndex #navbar.onScroll{
  padding: 0px;
  position: fixed;
  width: 100%;
  background: #f8f8f8;
  z-index: 99;
  box-shadow: 0px 0px 5px -1px grey;
}

#homeIndex .logo img{
  max-width: 120px;
}

#homeIndex #navbar.onScroll .logo img{
  max-width: 80px;
}

/*#homeIndex a {
  color: var(--colorDefault);
  text-transform: uppercase;
  line-height: normal;
  font-weight: bold;
}*/

#homeIndex .bodyIndex {
  padding: 60px 0;
  height: 80vh;
  place-content: stretch;
  background: var(--backgroundDefault);
  /*border-top-right-radius: 50px;
  border-top-left-radius: 50px;*/
}

#homeIndex #navbar a {
  font-family: 'Kalam-Bold';
  text-transform: none;
  font-size: 20px;
  padding: 12px 20px;
  color: var(--colorText);
}

#homeIndex #navbar a:hover {
  color: var(--colorDefault);
}

#homeIndex #navbar a.btn-default{
  color: #fff;
}

#homeIndex .bodyIndex p {
  margin-top: 30px;
  font-size: 20px;
}

#homeIndex .bodyIndex .card p{
  color: #004db9;
  margin-bottom: 0;
  padding: 15px;
  font-weight: 600;
  font-size: 18px;
}

#homeIndex .bodyIndex button {
  padding: 12px 35px;
}
#homeIndex p {
  font-size: 16px;
}
#homeIndex .footerIndex {
  padding: 15px 0;
  background: #f8f8f8;
}
#homeIndex .footerIndex p, #homeIndex .footerIndex a {
  color: #353535;
  text-transform: none;
  margin-bottom: 8px;
}

#bodyHowWorks, #bodyKnow, #bodyTax {
  padding: 80px 0;
  margin-top: -100px;
}
#bodyHowWorks .title, #bodyKnow .title, #bodyTax .title {
 margin-top: 15px;
 font-size: 20px;
}
#bodyHowWorks img {
  padding: 30px;
}
#bodyKnow #imageDemo {
  margin-bottom: 15px;
  box-shadow: 0px 0px 10px 1px #dbdbdb;
  border-radius: 12px;
}
#bodyKnow .itemKnow {
  box-shadow: 0px 0px 10px 1px #dbdbdb;
  padding: 30px 15px;
  border-radius: 8px;
  background: white;
  margin-bottom: 15px;
}
#bodyKnow .itemKnow .title{
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 5px;
}
#bodyKnow .itemKnow span{
  font-size: 14px;
}
#bodyKnow .itemKnow i{
  color: var(--colorDefault);
  font-size: 2.5em;
}
#bodyTax .itemTax {
  box-shadow: 0px 0px 10px 1px #dbdbdb;
  padding: 25px 15px;
  border-radius: 8px;
  background: white;
  margin-bottom: 15px;
  border: 3px solid #fff;
}
#bodyTax .itemTax .priceItemTax {
  background: var(--colorDefault);
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: inline-flex;
}
#bodyTax .itemTax .priceItemTax p{
  color: #fff;
  font-size: 2.0em;
}
#bodyTax .itemTax .priceItemTax i {
  color: #fff;
  font-size: 28px;
}
#bodyTax .itemTax:hover, #bodyTax .itemTax.active {
  border: 3px solid #009ce2;
}
.lineDivider {
  width: 100%;
  height: 320px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -50px;
}

/* Mobile Layout */
@media only screen and (max-width: 767px) {
  #homeIndex .bodyIndex {
    padding: 20px 0;
    height: auto;
  }
  #homeIndex .logo img{
    max-width: 80px;
  }
  .itensHome {
    height: auto;
  }
  .lineDivider {
    width: 100%;
    height: 200px;
    background-image: none !important;
  }
  /*#homeIndex a {
    color: white;
    text-transform: uppercase;
    font-size: 10px;
  }*/
}